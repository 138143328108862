import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe 
        src="https://sockfancy.retool.com/embedded/public/092fade7-e0a8-4a1f-b9df-d0e4119e417e" 
        title="RoofQuotes"
        width="100%"
        height="100%"
        style={{border: 'none'}}
      ></iframe>
    </div>
  );
}

export default App;
